<template>
  <div>
    <!--begin::Events-->
    <div class="card card-custom gutter-b">
      <div class="card-header pt-3 pb-3">
        <div class="card-title">
          <h3 class="card-label">Events List</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools" v-if="user_permissions.includes('chapter_add_event')">
            <div class="my-2 mr-3">
              <a
                @click="redirectToCreateEvent()"
                class="btn btn-primary font-weight-bolder"
              >
                <span class="svg-icon svg-icon-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M5.84026576,8 L18.1597342,8 C19.1999115,8 20.0664437,8.79732479 20.1528258,9.83390904 
                        L20.8194924,17.833909 C20.9112219,18.9346631 20.0932459,19.901362 18.9924919,19.9930915 
                        C18.9372479,19.9976952 18.8818364,20 18.8264009,20 L5.1735991,20 C4.0690296,20 3.1735991,19.1045695 
                        3.1735991,18 C3.1735991,17.9445645 3.17590391,17.889153 3.18050758,17.833909 L3.84717425,9.83390904 
                        C3.93355627,8.79732479 4.80008849,8 5.84026576,8 Z M10.5,10 C10.2238576,10 10,10.2238576 
                        10,10.5 L10,11.5 C10,11.7761424 10.2238576,12 10.5,12 L13.5,12 C13.7761424,12 14,11.7761424 14,11.5 
                        L14,10.5 C14,10.2238576 13.7761424,10 13.5,10 L10.5,10 Z"
                        fill="#000000"
                      ></path>
                      <path
                        d="M10,8 L8,8 L8,7 C8,5.34314575 9.34314575,4 11,4 L13,4 C14.6568542,4 16,5.34314575 16,7 L16,8 L14,8 
                        L14,7 C14,6.44771525 13.5522847,6 13,6 L11,6 C10.4477153,6 10,6.44771525 10,7 L10,8 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      ></path>
                    </g>
                  </svg>
                </span>
                Add Event
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_ba_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-card>
              <v-card-title class="pt-0 pr-0">
                <v-spacer></v-spacer>
                <div class="col-md-4 my-2 my-md-0">
                  <div class="input-icon">
                    <v-text-field
                      v-model="search"
                      single-line
                      placeholder="Search"
                      class="form-control"
                      hide-details
                    ></v-text-field>
                    <span>
                      <i class="flaticon2-search-1 text-muted"></i>
                    </span>
                  </div>
                </div>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="event_list"
                :search="search"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.name }}</td>
                    <td>{{ row.item.date }}</td>
                    <td>{{ row.item.time }}</td>
                    <td>{{ row.item.location }}</td>
                    <td>
                      <v-tooltip top v-if="user_permissions.includes('chapter_add_event') || is_company_admin == 1">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            fab
                            small
                            class="mx-1"
                            v-on="on"
                            @click="eventEdit(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Event</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            fab
                            small
                            class="mx-1"
                            v-on="on"
                            @click="eventShow(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>Show Event</span>
                      </v-tooltip>
                      <v-tooltip top v-if="user_permissions.includes('chapter_add_event') || is_company_admin == 1">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            fab
                            small
                            class="mx-1"
                            v-on="on"
                            @click="eventDelete(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-delete-forever</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete Event</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            fab
                            small
                            class="mx-1"
                            v-on="on"
                            @click="sendInvitation(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-email</v-icon>
                          </v-btn>
                        </template>
                        <span>Send Invitation</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </form>
      </div>
    </div>
    <!--end::Events-->
    <!--begin::Attended Events-->
    <template v-if="permissions.includes('events_attend_volunteer')">
      <attended-event-list></attended-event-list>
    </template>
    <!--end::Attended Events-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import EventBus from "@/core/services/store/event-bus";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { DELETE_EVENT } from "@/core/services/store/event.module";
import { GET_COMPANY } from "@/core/services/store/user.module";
import Swal from "sweetalert2";
import axios from "axios";
import AttendedEventList from "./AttendedEventsList";

export default {
  name: "eventsList",
  vuetify: new Vuetify(),
  components: {
    "attended-event-list": AttendedEventList
  },
  data() {
    return {
      permissions: [],
      user_permissions: [],
      event_list: [],
      search: "",
      is_company_admin : 2,
      headers: [
        { text: "Name", align: "left", value: "name", width: "25%" },
        { text: "Date", value: "date", width: "10%" },
        { text: "Time", value: "time", width: "10%" },
        { text: "Location", value: "location", width: "25%" },
        { text: "Action", sortable: false, value: "action", width: "25%" }
      ],
    };
  },
  watch: {
    getPermissions: function(newVal) {
      this.permissions = newVal;
    },
    getUserPermissions: function(newVal) {
      this.user_permissions = newVal;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig","getCompany","getUserPermissions", "getPermissions"]),
    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    let context = this;
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Events" }]);
    this.$store.dispatch(GET_COMPANY);
    EventBus.$on("GET_COMPANY", function() {});
    EventBus.$on("GET_IS_COMPANY_ADMIN", function(newVal) {
        context.is_company_admin = newVal;
    });
    this.getEventList();
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    eventDelete(event_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Event details!",
        showCancelButton: true,
        confirmButtonText: "Delete"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_EVENT, event_id).then(() => {
            this.getEventList();
          });
        } else if (result.isDismissed) {
          Swal.fire("Event is safe.", "", "info");
        }
      });
    },
    eventShow(event_id) {
      this.$router.push({
        name: "events.view",
        params: { id: event_id }
      });
    },
    sendInvitation(event_id){
        let context = this;
        axios({
          method: "get",
          url: "send-event-invitation?event_id="+event_id,
          baseURL: process.env.VUE_APP_ACBACK_URL
        }).then(
          function(result) {
            if(result.data.status == 0){
              Swal.fire("Error", result.data.message, "error");
            }else{
              Swal.fire("Success", result.data.message, "success");
            }
          },
          function() {
            Swal.fire("Error", "Error in sending Event Invitation", "info");
          }
        );
    },
    redirectToCreateEvent() {
      this.$router.push({ name: "eventsCreate" });
    },
    getEventList() {
      let context = this;
      axios({
        method: "get",
        url: "events",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.event_list = result.data.events;
        },
        function() {
          Swal.fire("Error", "Error in fetching Event list", "info");
        }
      );
    },
    eventEdit(event_id) {
      this.$router.push({
        name: "events.edit",
        params: { id: event_id }
      });
    }
  }
};
</script>
