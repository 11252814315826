<template>
  <div class="card card-custom gutter-b" v-if="attended_event_list.length > 0">
      <div class="card-header pt-3 pb-3">
        <div class="card-title">
          <h3 class="card-label">Events Joined/Volunteered</h3>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_ba_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-card>
              <v-card-title class="pt-0 pr-0">
                <v-spacer></v-spacer>
              </v-card-title>
              <v-data-table
                :headers="attended_event_headers"
                :items="attended_event_list"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.name }}</td>
                    <td class="text-capitalize">
                      {{ row.item.available_for_type }}
                    </td>
                    <td>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            fab
                            small
                            v-on="on"
                            @click="eventShow(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-eye</v-icon>
                          </v-btn>
                        </template>
                        <span>Show Event</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </form>
      </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "AttendEventList",
  vuetify: new Vuetify(),
  data() {
    return {
      attended_event_list: [],
      attended_event_headers: [
        { text: "Name", align: "left", value: "name", width: "50%" },
        { text: "Attend As", value: "available_for_type", width: "30%" },
        { text: "Status", value: "status", width: "20%" }
      ]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    let context = this;
    context.getAttendedEventList();
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    eventShow(event_id) {
      this.$router.push({
        name: "events.view",
        params: { id: event_id }
      });
    },
    getAttendedEventList() {
      let context = this;
      axios({
        method: "get",
        url: "get-attended-events",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.attended_event_list = result.data.events;
        },
        function() {
          Swal.fire("Error", "Error in fetching Event list", "info");
        }
      );
    },
    redirectToEventsList() {
      let context = this;
      context.$router.go(-1);
    }
  }
};
</script>
